.chart-title {
    font-size: 1.5em;
    font-weight: bold;
    margin-bottom: 0.5em;
    color: white;
    align-self: center;
    align-content: center;
}

.chart-buttons {
    position: absolute;
    right: 30%;
}

.chart-buttons-selected {
    background: white;
    color: black;
}

.chart-buttons-default {
    background-color: gray;
    color: white;
}

.bar-chart {
    width: 100%;
    height: 100%;
}