.mapbox {
  margin-top: 1rem;

  &-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 90vh;

    #map {
      // border: 1px solid #ccc;
      display: flex;
      flex-direction: row;
      background-color: var(--text-color);
      width: 100%;
      height: 100%;
      // padding: 0px;
    }

    #menu {
      position: absolute;
      background: lightgray;
      padding: 10px;
    }

    .json-content {
      // background-color: #f7f7f7;
      border: 1px solid var(--text-color);
      // margin-left: 1%;
      // padding: 0px;
      margin-top: 0%;
      width: 30%;
      overflow: auto;
      height: 100%;

      .string {
        color: green;
      }

      .number {
        color: darkorange;
      }

      .boolean {
        color: blue;
      }

      .null {
        color: magenta;
      }

      .key {
        color: red;
      }
    }

    .analysis-content {
      width: 40%;
      height: 100%;
    }

    .linear-progress {
      padding-top: 15px;
    }

    .json-content-record {
      background-color: var(--theme-color);
      border: 1px solid var(--text-color);
      margin-left: 1%;
      margin-top: 0;
      width: 40%;
      height: 50%;

      .MuiCardContent-root {
        padding: unset !important;
      }

      .string {
        color: green;
      }

      .number {
        color: darkorange;
      }

      .boolean {
        color: blue;
      }

      .null {
        color: magenta;
      }

      .key {
        color: red;
      }

      &-inner {
        background-color: #fff;
        height: 20%;
        padding: 20px;
        align-self: center;
        position: relative;
        border-bottom: 0.5px solid var(--selected-color);

        &-header {
          position: sticky;
          top: 0;
          display: flex;
          justify-content: space-between;
          color: var(--theme-color);
          align-items: center;
          z-index: 1;
          font-size: 14px;
          font-weight: 600;

          .switch-button {
            margin-right: 20px;
            margin-top: -5px;
            float: left;
          }
        }

        &-content {
          height: calc(100% - 28px);
          font-size: 10px;
          overflow: auto;
        }
      }
    }
  }
}

.mapboxgl-ctrl-top-right {
  width: 30% !important;

  .mapboxgl-ctrl-geocoder {
    float: left !important;
    margin-top: -70% !important;
  }
}

.yellow-dot {
  height: 18px;
  width: 18px;
  margin-right: 10px;
  margin-top: 5px;
  background-color: #ffbd00;
  border-radius: 50%;
  display: inline-block;
}

.no-color-dot {
  height: 18px;
  width: 18px;
  margin-right: 10px;
  border-radius: 50%;
  display: inline-block;
}

.container-stack {
  display: flex;
  align-items: center; /* Vertically center the contents */
  height: auto;
}

.red-dot {
  height: 18px;
  width: 18px;
  margin-right: 10px;
  margin-top: 5px;
  background-color: #d81159;
  border-radius: 50%;
  display: inline-block;
}

.violet-dot {
  height: 18px;
  width: 18px;
  margin-right: 10px;
  margin-top: 5px;
  background-color: #7678ed;
  border-radius: 50%;
  display: inline-block;
}

.blue-dot {
  height: 18px;
  width: 18px;
  margin-right: 10px;
  margin-top: 5px;
  background-color: #0496ff;
  border-radius: 50%;
  display: inline-block;
}

.green-dot {
  height: 18px;
  width: 18px;
  margin-right: 10px;
  margin-top: 5px;
  background-color: #31572c;
  border-radius: 50%;
  display: inline-block;
}

.small-text {
  font-size: 10px;
}

.custom-icon {
  color: red;
}

.mapboxCon {
  margin-top: 1rem;

  &-container {
    display: flex;
    // flex-direction: row;
    width: 100%;
    height: 90vh;
    align-items: center;
    justify-content: center;
    padding-bottom: 1rem;

    #map {
      flex-direction: row;
      background-color: var(--text-color);
      width: 100%;
      height: 100%;
    }

    #mapBox {
      display: flex;
      flex-direction: row;
      background-color: var(--text-color);
      width: 100%;
      height: 100%;
    }
  }
}