a {
    color: #CF8907; /* Change the default link color */
}

a:visited {
    color: #CF8907; /* Change the color of visited links */
}

.h1Text {
    font-size: 1em;
    padding-left: 3rem;
    padding-right: 3rem;
}