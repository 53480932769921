/* @import url('https://fonts.googleapis.com/css2?family=Barlow:wght@400;700&display=swap'); */

@font-face {
    font-family: 'Lato';
    src: url('./assets/fonts/Lato-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Lato';
    src: url('./assets/fonts/Lato-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Lato';
    src: url('./assets/fonts/Lato-Black.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}

body {
    margin: 0;
    font-family: 'Lato';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #1F212B;
}

p {
    color: white;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
