.mapboxCon {
  &-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 60vh;
    align-items: center;
    justify-content: center;

    #map {
      flex-direction: row;
      background-color: var(--text-color);
      width: 60%;
      height: 100%;
    }

    #mapBox {
      display: flex;
      flex-direction: row;
      background-color: var(--text-color);
      width: 100%;
      height: 100%;
    }

    .json-content {
      background-color: #f7f7f7;
      border: 1px solid var(--text-color);
      margin-left: 1%;
      margin-top: 0%;
      width: 40%;
      overflow: auto;
      height: 100%;

      .string {
        color: green;
      }

      .number {
        color: darkorange;
      }

      .boolean {
        color: blue;
      }

      .null {
        color: magenta;
      }

      .key {
        color: red;
      }
    }
  }
}